@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Co Text';
        src: url("/src/assets/fonts/Co-Text.ttf") format('truetype');
    }

    @font-face {
        font-family: 'Co Text Light';
        src: url("/src/assets/fonts/Co-Text-Light.otf") format('opentype');
    }

    @font-face {
        font-family: 'Co Text Lt';
        src: url("/src/assets/fonts/Co-Text-Lt.ttf") format('truetype');
    }

    @font-face {
        font-family: 'Co Headline';
        src: url("/src/assets/fonts/Co-Headline.otf") format('opentype');
    }

    @font-face {
        font-family: 'Co Headline Bold';
        src: url("/src/assets/fonts/Co-Headline-Bold.otf") format('opentype');
    }

    @font-face {
        font-family: 'Co Headline Light';
        src: url("/src/assets/fonts/Co-Headline-W01-Light.ttf") format('truetype');
    }
}
